@font-face {
    font-family: "HU";
    src: url(fonts/HULifeStyle.ttf);
}
@font-face {
    font-family: "Sig";
    src: url(fonts/SignikaNegative.ttf);
}
@font-face {
    font-family: "Rubik";
    src: url(fonts/Rubik.ttf);
}
@font-face {
    font-family: "Noto";
    src: url(fonts/NotoSans-Medium.ttf);
}
@font-face {
    font-family: "FiraSans";
    src: url(fonts/FiraSansCondensed-BoldItalic.ttf);
}

* {
    box-sizing: border-color;
}

body {
    margin: 0;
}

p {
    margin-top: 10px;
    margin-bottom: 10px;
}

.nav { 
    color: white;
    background-color: #930D0D;
    text-align: center;
    align-items: stretch;

}

.nav a { 
    color: inherit;
    text-decoration: none;
}

.nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: min(max(7rem), 5vw);
    font-size: min(max(30px), 5vw);
    font-family: "Sig";
    justify-content: center;
    background-color: rgb(45, 45, 45);
}

.title {
    font-size: min(max(60px), 10vw);
    font-family: "HU", sans-serif;
    height: 120%;
}

.top {
    padding-bottom: 5px;
}

.top_header {
    margin: 0;
    padding-top: 1rem;
    font-family: "FiraSans", sans-serif;
    font-size: 40px;
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-underline-offset: 4px;
    text-align: center;
    font-size: min(max(40px), 4vw);
}

.body_of_text {
    font-family: "Rubik", sans-serif;
    padding-left: 3rem;
    padding-right: 3rem;
}

h3 {
    margin: 0;
    padding: 0;
    font-size: min(max(30px), 4vw);
}

p, #services ul, #links ul {
    font-size: min(max(20px), 3vw);
    margin-top: 10px;
    margin-bottom: 20px;
}

#instructions {
    text-align: center;
}

form {
    font-family: "Noto", sans-serif;
    color: white;
    background-color: #930D0D;
    width: 500px;
    border-radius: 10px;
}

.f {
    margin-bottom: 300px;
}

.form_container {
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
}

form label {
    display: block;
}

form input, form textarea {
    display: block;
    width: 100%;
    outline: none;
    text-decoration: none;
    border:none;
    font-family: "Noto" !important;
}


form textarea { 
    height: 200px;
    resize: none;
}

form input:disabled, form textarea:disabled { 
    background-color:rgb(123, 123, 123);
}

.end { 
    display: flex;
    width: 100%;
}

#s {
    padding-top: 20px;
}

#submitButton {
    width: 195px;
    height: 50px;
}

#rec { 
    transform:scale(.77);
    -webkit-transform:scale(.77);
    transform-origin:0 0;
    -webkit-transform-origin:0 0;
    padding-top: 20px;
    padding-left: 39px;
    width: 240px;
}

form input[type="submit"] {
    display: block;
}

.blocked { 
    background-color:rgb(123, 123, 123);
}

.loader_container {
    margin-top: min(10px, 4vw);
    text-align: center;
}

.loader {
    width: min(200px, 7vw);
    height: min(200px, 7vw);
    border: min(12px, 1.1vw) solid #930D0D;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 

.imgs {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 3rem;
    padding-right: 3rem;
    opacity: 0;
}

#codesweetly-lightbox {
    background-color: black !important;
}

#codesweetly-lightbox > span:nth-child(n), #codesweetly-lightbox > section > * {
    background-color: transparent !important;
}

#codesweetly-lightbox > span {
    font-family: "Sig" !important;
}

img { 
    width: 100%;
    height: auto;
    object-fit: contain;
}

.yarl__slide_image {
    background-color: black;
}

::-webkit-scrollbar {
    width: 10px;
}
  
::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb:hover {
    width: 10px;
    background: rgb(45, 45, 45);
}

.active { 
    color: rgb(188, 188, 188);
}
